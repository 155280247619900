import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import messages from "api/messages";

// * components
import CustomCheckbox from "../CustomCheckbox";
import CustomSelect from "../CustomSelect";
import { FormErrorText } from "theme/StyledComponents";
import { UncontrolledInput } from "../CustomInput/CustomInput";
import { Prompt } from "react-router-dom";

// * ActionExpirationForm controls the inputs for defining an expiration period and periodAmount on an action
const ActionExpirationForm = ({ emitExpiration }) => {
  const SELECT_OPTION_ID = "SELECT_OPTION";
  const expirationPeriodValues = [
    { name: "Select Option", id: SELECT_OPTION_ID, min: 0, max: 0 },
    { name: "Week(s)", id: 1, min: 1, max: 52, enumValue: "WEEK" },
    { name: "Month(s)", id: 2, min: 1, max: 48, enumValue: "MONTH" }
  ];

  // * warningMessage displays expiration periodAmount max and min when invalid value given
  const [warningMessage, setWarningMessage] = useState(null);

  // * The Form
  const {
    register,
    watch,
    formState: { isDirty }
  } = useForm({
    defaultValues: {
      period: SELECT_OPTION_ID,
      periodAmount: null,
      expires: false
    }
  });

  const expires = watch("expires");
  const period = watch("period");
  const periodAmount = watch("periodAmount");

  const emitValues = () => {
    if (expires && !!period && period !== "SELECT_OPTION" && !!periodAmount) {
      setWarningMessage("");
      const foundPeriod = expirationPeriodValues.find((val) => val.id === +period);
      if (foundPeriod && periodAmount <= foundPeriod.max && periodAmount >= foundPeriod.min) {
        emitExpiration({ period: foundPeriod.enumValue, periodAmount, expires });
      } else {
        emitExpiration({ period: null, periodAmount: null, expires });
        setWarningMessage(
          `* Expiration Period Amount for ${foundPeriod.name}s must be from ${foundPeriod.min} to ${foundPeriod.max}`
        );
      }
    } else {
      emitExpiration({ period: null, periodAmount: null, expires });
    }
  };

  // * simple use effect: anytime any value changes -> emit the values
  useEffect(() => {
    emitValues();
  }, [expires, periodAmount, period]);

  return (
    <div
      className="d-inline-flex justify-content-start grid gap-2 w-100 align-items-center flex-wrap"
      data-testid="ACTION_EXPIRATION_FORM"
    >
      <Prompt message={messages.UNSAVED_CHANGES} when={isDirty} />
      <CustomCheckbox
        checked={expires ?? false}
        formRegister={register}
        name="expires"
        testId="EXPIRATION_FORM_CHECKBOX"
      />
      <div>Result Expires After</div>
      <UncontrolledInput
        labelText="VALUE"
        formRegister={register}
        name="periodAmount"
        maxWidth="4rem"
        disabled={!expires}
        className={!!warningMessage && expires && "error"}
        testId="EXPIRATION_FORM_PERIOD_AMOUNT_INPUT"
      />
      <CustomSelect
        label="PERIOD"
        formRegister={register}
        name="period"
        width="10rem"
        options={expirationPeriodValues}
        displayKey="name"
        valueKey="id"
        disabled={!expires}
        testId="EXPIRATION_FORM_PERIOD_INPUT"
      />
      {warningMessage && expires && (
        <FormErrorText data-testid="FORM_ERROR_TEXT" className="m-auto ms-0 mb-2">
          {warningMessage}
        </FormErrorText>
      )}
    </div>
  );
};

export default ActionExpirationForm;
