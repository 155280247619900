import { ATTRIBUTES_TYPES } from "features/Attributes/utils";
import { keyBy, map, mapValues, omit } from "lodash";
import { ACTION_TYPES, ACTION_TYPES_DICT } from "features/Actions/utils";

export const attributeFromFormData = (id, index) => (formData) => {
  const r = {
    ...omit(formData, ["iconUrl", "createdAt", "attributeValues", "connection"]),
    id,
    index,
    image: formData?.image,
    displayLocation: formData.displayLocation,
    value: formData.type === ATTRIBUTES_TYPES.WHOLE_NUMBER ? formData.value : formData.attributeValues[0]?.id
  };

  return {
    attribute: r,
    attributeValues: map(formData.attributeValues, (av, i) => ({
      ...omit(av, ["isNew", "iconUrl"]),
      id: av.id,
      index: i
    }))
  };
};

export const getSelectedAttribute = (id, attributes) => keyBy(attributes, "id")[id] ?? attributes[0];

export const inputValidation = { required: true };

export const mapActionToForm = (a, roles) => {
  const base = {
    id: a.id,
    operator: a.operator.value,
    value: a.value,
    attributeName: a.attribute.name,
    name: a.name,
    source: ACTION_TYPES_DICT[a.type].value,
    type: a.type,
    approvalRequired: a.approvalRequired,
    displayValue: a.displayValue,
    limit: a?.limit,
    applyStrategy: a?.applyStrategy,
    result: a?.result,
    autoAddUsers: a?.autoAddUsers ?? false,
    autoAddUsersRole: roles.find((role) => role.role === a?.autoAddUsersRole)?.displayValue ?? "Observed Only",
    expirationPeriod: a?.expirationPeriod === "WEEK" ? "Week" : a?.expirationPeriod === "MONTH" ? "Month" : "",
    expirationPeriodAmount: `${a?.expirationPeriodAmount}` ?? ""
  };

  return a.type === ACTION_TYPES_DICT.SLACK.id
    ? { ...base, channelId: a?.channelId, incomingEventType: a?.incomingEventType }
    : base;
};

export const maybeJson = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
};

export const passphraseValidation = {
  required: "You must specify a passphrase",
  minLength: {
    value: 20,
    message: "Passphrase must have at least 20 characters"
  },
  maxLength: {
    value: 256,
    message: "Passphrase must be no more than 256 characters"
  }
};

export const ruleFromFormData = (index, ruleId, operators) => (formData) => {
  const rule = {
    ...formData,
    index,
    ruleConditions: map(formData.ruleConditions, (rc, i) => {
      // remove keys that we don't need, re: they came from GET request but don't need to be saved
      const keysToOmit = ["isNew", "attribute", "operator", "modifier", "secondaryAttribute"];
      if (rc.customActionId && rc.customActionId === ACTION_TYPES.MANUAL_ENTRY) {
        keysToOmit.push("customActionId");
      }

      // * when a custom action is selected in the THEN condition the operatorId
      // * tmp fix: put a placeholder in there
      if (!rc.operatorId) {
        rc.operatorId = operators[0].id;
      }
      const cleanedKeys = omit(rc, keysToOmit);
      // convert empty strings values to null re: backend validation
      const cleanedValues = mapValues(cleanedKeys, (val, key) => {
        return val === "" ? null : val;
      });
      return {
        ...cleanedValues,
        index: i
      };
    }),
    id: ruleId
  };
  return rule;
};
