import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { OverflowTooltip } from "../../components/OverflowTooltip";
import { ACTION_TYPES, ACTION_TYPES_DICT, APPLY_TYPE, getApprovalDisplay, getLimitDisplay } from "./utils";
import CollapsibleCard from "components/CollapsibleCard";
import { useSelector } from "react-redux";
import { selectProjectData } from "features/userData/userDataSlice";
import { useCopyToClipboard } from "hooks/dom.hooks";
import { encrypt } from "utils/encryption.utils";

const TitleContainer = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  flex-wrap: wrap;
`;

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
  min-width: 12rem;
`;

const BodyContainer = styled.div`
  display: flex;
  justify-content: start;
  padding-left: 5rem;
`;

const LimitText = styled.div`
  width: 10rem;
  margin: 0 5px;
`;

const MagicLinkButton = styled.button.attrs({ className: "btn rounded-pill btn-outline-success border-1 ms-2" })`
  outline: #6fc45c !important;
  color: #6fc45c !important;
  border-color: #6fc45c !important;
  font-size: 0.65rem;
  &:hover {
    color: white !important;
    background: #6fc45c !important;
  }
`;

export function CustomAction({ action, handleDelete }) {
  const projectData = useSelector(selectProjectData);
  const redirect = `/dashboard/actions?actionId=${action.id}&projectId=${projectData?.id}&accountId=${projectData?.accountId}`;
  const hash = encrypt(redirect);
  const link = `${window.location.origin}?rd=${encodeURIComponent(hash)}`;
  const copyToClipboard = useCopyToClipboard(link);

  const setExpirationPeriod = (expirationPeriodAmount, expirationPeriod) => {
    return +expirationPeriodAmount > 1 ? `${expirationPeriod}s` : expirationPeriod;
  };

  const TitleRow = () => (
    <TitleContainer>
      <FieldContainer data-testid={`FIELD_CONTAINER-${action.name}`}>
        {action.source}
        {action?.type === ACTION_TYPES.MAGIC_LINK && (
          <MagicLinkButton data-testid={`COPY_LINK-${action.name}`} data-link={link} onClick={copyToClipboard}>
            Copy link
          </MagicLinkButton>
        )}
      </FieldContainer>
      <FieldContainer style={{ width: "16rem", maxWidth: "16rem", verticalAlign: "center !important" }}>
        <OverflowTooltip target={`name-${action.id}`} text={action.name} />
      </FieldContainer>
      <FieldContainer style={{ width: "16rem", verticalAlign: "center !important" }}>{action?.result}</FieldContainer>
    </TitleContainer>
  );

  const CardBody = () => (
    <BodyContainer className="d-flex flex-column w-100">
      <div className="d-flex flex-row justify-content-start w-100 mb-3">
        <LimitText style={{ width: "20rem" }}>Track Per: {APPLY_TYPE[action?.applyStrategy]?.display}</LimitText>
        <LimitText style={{ width: "20rem" }}>Limit: {getLimitDisplay(action?.limit)}</LimitText>

        {action.expirationPeriodAmount && action.expirationPeriod ? (
          <LimitText style={{ width: "20rem" }} data-testid={`EXPIRATION_TEXT_${action.name}`}>
            Result Expires After {action?.expirationPeriodAmount}{" "}
            {setExpirationPeriod(action?.expirationPeriodAmount, action?.expirationPeriod)}
          </LimitText>
        ) : (
          <LimitText style={{ width: "20rem" }} data-testid={`EXPIRATION_TEXT_${action.name}`}>
            Result Does Not Expire
          </LimitText>
        )}
      </div>
      <div className="d-flex flex-row justify-content-start w-100">
        {action.type === "MANUAL_ENTRY_PARTICIPANT" ? (
          <LimitText style={{ width: "20rem" }}>{getApprovalDisplay(action.approvalRequired)}</LimitText>
        ) : (
          <LimitText style={{ width: "20rem" }}>Needs No Approval</LimitText>
        )}
        {action.autoAddUsers ? (
          <LimitText style={{ width: "25rem" }} data-testid={`AUTO_ADD_ROLE_TEXT_${action.name}`}>
            Auto-Add Users as Role: {action?.autoAddUsersRole}{" "}
          </LimitText>
        ) : (
          <LimitText style={{ width: "25rem" }}>Users Not Automatically Added</LimitText>
        )}
      </div>
    </BodyContainer>
  );
  return (
    <CollapsibleCard
      TitleRow={TitleRow}
      Body={CardBody}
      onDelete={handleDelete}
      testId={`COLLAPSIBLE_CARD_${action.name}`}
    />
  );
}

CustomAction.propTypes = {
  action: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    result: PropTypes.string.isRequired,
    limit: PropTypes.string.isRequired,
    applyStrategy: PropTypes.string.isRequired,
    approvalRequired: PropTypes.bool.isRequired,
    expirationPeriodAmount: PropTypes.string,
    expirationPeriod: PropTypes.string
  }).isRequired,
  handleDelete: PropTypes.func.isRequired
};

CustomAction.defaultProps = {
  action: {},
  handleDelete: () => ({})
};
