import React, { useEffect, useState } from "react";
import CustomSelect from "../CustomSelect";
import { useForm } from "react-hook-form";
import { useQuery } from "@apollo/client";
import { GET_ROLES } from "../../api/accounts";

// * A drop in select input for selecting roles
const RoleSelectionForNewUsers = ({
  setRole,
  small = false, // * Bulk log and CSV Upload require smaller text vs Add Users
  disabled = false,
  hideSelectionMessage = false, // * CSV Upload modal combines checkbox label the role select
  roleFromConfig = null,
  customActionForm = false,
  flexRow = false // * Display role message in row vs column
}) => {
  const { data, loading } = useQuery(GET_ROLES);

  const { register, watch, setValue } = useForm({
    defaultValues: {
      role: roleFromConfig ? data?.getRolesForDisplay.find((role) => role.value === roleFromConfig)?.id : 1
    }
  });

  const role = watch("role");

  const [selectedRole, setSelectedRole] = useState(role);

  // * Emit to parent and set to component role anytime new one selected
  useEffect(() => {
    const foundRole = data?.getRolesForDisplay[+role - 1];
    setSelectedRole(foundRole);
    setRole(foundRole?.role);
  }, [role, setSelectedRole, data?.getRolesForDisplay]);

  // * Loaded CSV Upload configs pass a role down from the parent: honor passed role
  useEffect(() => {
    const found = data?.getRolesForDisplay.find((roleForDisplay) => roleForDisplay.role === roleFromConfig);
    if (found) {
      setValue("role", found?.id);
    }
  }, [roleFromConfig, setValue, data?.getRolesForDisplay, setRole]);

  let filteredRolesForCustomActionForm = [];

  if (data?.getRolesForDisplay.length > 0) {
    filteredRolesForCustomActionForm = data?.getRolesForDisplay.map((role) => role);
    filteredRolesForCustomActionForm.splice(1, 2);
  }

  return (
    <div
      className={
        !hideSelectionMessage
          ? "d-flex justify-content-center w-100"
          : flexRow
            ? "ms-2"
            : "d-flex flex-column w-100 m-1"
      }
    >
      {small ? (
        <p className="m-auto">{!hideSelectionMessage && "Choose Role for New Users"}</p>
      ) : (
        <h5 className="m-auto w-100">{!hideSelectionMessage && "Choose Role for Added Users"}</h5>
      )}
      <CustomSelect
        label="ROLE"
        className={small ? "me-1 ms-1 mt-1" : ""}
        displayKey="displayValue"
        formRegister={register}
        name="role"
        width={small ? "60%" : flexRow ? "100%" : "75%"}
        height={flexRow ? "3rem" : "2.5rem"}
        options={!customActionForm ? data?.getRolesForDisplay : filteredRolesForCustomActionForm}
        valueKey="id"
        tooltips={true}
        disabled={disabled || loading}
        defaultValue={!customActionForm ? data?.getRolesForDisplay[0].id : data?.getRolesForDisplay[3].id}
      />
      {!disabled && !loading && (
        <div
          style={small ? { fontSize: ".8rem", color: selectedRole?.color } : { color: selectedRole?.color }}
          className="m-auto"
          data-testid="SELECTED_ROLE_MESSAGE"
        >
          {selectedRole?.message ?? data?.getRolesForDisplay[0].message}
        </div>
      )}
    </div>
  );
};

export default RoleSelectionForNewUsers;
