import { orderBy } from "lodash";
import { formatDateForDisplay } from "../../utils/date.utils";

export const allUsersActionsLogColumns = [
  {
    field: "dateApplied",
    headerName: "Date Applied",
    flex: 1.5,
    minWith: 150,
    valueGetter: (params) => formatDateForDisplay(params.row.dateApplied)
  },
  {
    field: "creator",
    headerName: "Created By",
    cellClassName: "text-start",
    flex: 1.5,
    renderCell: (params) => {
      return <div>{params.row.creator}</div>;
    }
  },
  {
    field: "user",
    headerName: "User",
    flex: 1,
    valueGetter: (params) => {
      return params.row?.user?.fullName;
    }
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1.25,
    valueGetter: (params) => {
      return params.row?.user?.email ?? "";
    }
  },
  {
    field: "name",
    headerName: "Action",
    flex: 2,
    renderCell: (params) => {
      return params.row.expired ? (
        <div>
          {params.row.name}
          <br />
          <span style={{ color: "red" }}>Expired {formatDateForDisplay(params.row.expirationDate)}</span>
        </div>
      ) : params.row.expirationDate ? (
        <div>
          <div>{params.row.name}</div>
          <div>{`Expires ${formatDateForDisplay(params.row.expirationDate)}`}</div>
        </div>
      ) : (
        <div>{params.row.name}</div>
      );
    }
  },
  {
    field: "actionDate",
    headerName: "Action Date",
    type: "date",
    flex: 1,
    cellClassName: "text-start",
    headerAlign: "left",
    renderCell: (params) => {
      return <div>{formatDateForDisplay(params.row.actionDate)}</div>;
    }
  },
  {
    field: "result",
    headerName: "Result",
    flex: 1.5,
    renderCell: (params) =>
      params.row.approvalStatus === "APPROVED" && !params.row.expired ? (
        <div className="MuiDataGrid-cellContent">{params.row.result}</div>
      ) : (
        <strong className="MuiDataGrid-cellContent" style={{ color: "red" }}>
          {" "}
          {params.row.approvalStatus === "EXPIRED_NOT_APPROVED" ? "Expired: Not Approved" : params.row.result}
        </strong>
      )
  },
  {
    field: "calculatedValue",
    headerName: "Value",
    flex: 1.25,
    minWidth: 125,
    valueGetter(params) {
      // * Only show value if action is APPROVED
      if (params.row.approvalStatus === "APPROVED") {
        const parsed = parseInt(params.row.calculatedValue);
        if (Number.isNaN(parsed)) {
          return params.row.calculatedValue;
        } else {
          return parsed;
        }
      }
    }
  }
];

export const getOrderedActions = (actions) => {
  const ordered = orderBy(actions, ({ dateApplied, actionDate }) => dateApplied || actionDate || "", ["desc"]);
  return ordered;
};
