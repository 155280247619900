import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { HighlightWithinTextarea } from "react-highlight-within-textarea";
import { Label } from "reactstrap";
import { debounce, keyBy } from "lodash";
import confirmAction from "reactstrap-confirm";
import { useSelector } from "react-redux";

import CustomSelect from "components/CustomSelect";
import { ActionFormButton, StyledHighlightTextArea } from "theme/StyledComponents";
import { SUBMIT_ACTION } from "api/actions";
import { useHandleError, usePreviousValue } from "../../hooks/api.hooks";
import { useSubmit, useShouldResetForm } from "../../hooks/form.hooks";
import { useCheckActionLimit } from "../../hooks/application.hooks";
import CustomDatePicker from "components/CustomDatePicker";
import { approvalDetailsHighlight } from "components/MultipleEmailInput/helper";
import { getLimitDisplay } from "features/Actions/utils";
import { selectUserId } from "app/selectors";
import { DateTime } from "luxon";
import messages from "../../api/messages";
import { calculateExpirationDate, checkDateExpired } from "../../utils/date.utils";

function UserCreatedActionForm({
  handleClearForm = () => ({}),
  action = {},
  submittableActions = [],
  refetchDashboard
}) {
  const handleError = useHandleError();
  const userId = useSelector(selectUserId);
  const checkActionLimit = useCheckActionLimit();
  const { setValue, register, reset, watch, handleSubmit, getValues } = useForm({
    defaultValues: { approvalDetails: "", approvalDetailsErrors: null, expirationDate: null },
    mode: "onChange"
  });
  const approvalDetails = watch("approvalDetails");
  const approvalDetailsErrors = watch("approvalDetailsErrors");
  const isValid = !approvalDetailsErrors && submittableActions.length > 0;

  const previousAction = usePreviousValue(action);
  const [submitAction, { loading: submitting }] = useMutation(SUBMIT_ACTION);

  const extractFormData = (formValues) => {
    return {
      actionDate: formValues.actionDate,
      approvalDetails,
      customActionId: formValues.actionId,
      userId,
      expirationDate: formValues.expirationDate
    };
  };

  const onSubmit = useSubmit({
    mutation: submitAction,
    clearForm: handleClearForm,
    dataPath: SUBMIT_ACTION.definitions[0].name.value,
    onSuccess: () => {
      handleClearForm();
      refetchDashboard();
    },
    extractFormData
  });

  const debouncedOnSubmit = debounce(
    async () => {
      let forceExpired = true;
      const customActionId = getValues("actionId");
      const actionDate = getValues("actionDate");
      const customAction = keyBy(submittableActions, "id")[customActionId];
      const calculatedExpirationDate = calculateExpirationDate(
        customAction.expirationPeriod,
        customAction.expirationPeriodAmount,
        actionDate ? DateTime.fromSQL(actionDate) : DateTime.now()
      );
      setValue("expirationDate", calculatedExpirationDate);
      const alreadyExpired = checkDateExpired(calculatedExpirationDate);

      if (alreadyExpired) {
        forceExpired = await confirmAction({
          confirmText: "Yes",
          cancelText: "No",
          message: messages.EXPIRED_ACTION_WARNING,
          confirmColor: "btn btn-success shadow border-0",
          cancelColor: "btn btn-danger shadow border-0"
        });
      }

      if (forceExpired) {
        const { success: withinTimeFrame } = await checkActionLimit(customActionId, userId);
        if (!withinTimeFrame) {
          await confirmAction({
            title: "Action Limit Reached",
            message: `This action is limited to ${getLimitDisplay(customAction.limit)}. Please try again later.`,
            confirmText: "Ok",
            cancelText: "",
            confirmColor: "btn btn-success shadow border-0",
            cancelColor: "invisible"
          });
          handleClearForm();
          return;
        }
        handleSubmit(onSubmit)().catch((e) => {
          handleError(e.message);
        });
      } else {
        handleClearForm();
      }
    },
    1000,
    { leading: true }
  );

  useShouldResetForm(action, previousAction, reset);

  const handleInputChange = React.useCallback(
    (value) => {
      setValue("approvalDetails", value);
      setTimeout(() => {
        // need one tick to be up to date
        setValue("approvalDetailsErrors", document.getElementsByClassName("red").length);
      });
    },
    [setValue]
  );
  const DESCRIPTION_CHAR_LIMIT = 500;

  return (
    <form className="rounded shadow px-4 py-5" onSubmit={handleSubmit(debouncedOnSubmit)}>
      <h4 className="text-center mb-3">Submit a new action </h4>

      <div className="d-flex mb-4">
        <div className="d-flex w-100 justify-content-between">
          <CustomDatePicker formRegister={register} name="actionDate" width="9rem" />
          <div
            style={{
              width: "10rem",
              display: "flex",
              justifyContent: "space-around"
            }}
          >
            <ActionFormButton
              type="submit"
              disabled={submitting || !isValid}
              className="btn btn-outline-success shadow border-0"
              data-testid="SUBMIT_USER_ACTION"
            >
              <i className="fa fa-check" />
            </ActionFormButton>
            <ActionFormButton
              type="button"
              disabled={submitting || !isValid}
              className="btn shadow border-0 btn-outline-primary"
              buttonType="undo"
              onClick={() => reset()}
            >
              <i className="fa fa-undo" />
            </ActionFormButton>
            <ActionFormButton
              type="button"
              className="btn border-0 shadow btn-outline-danger"
              onClick={handleClearForm}
              buttonType="delete"
            >
              <i className="fa fa-times" />
            </ActionFormButton>
          </div>
        </div>
      </div>
      <div className="w-100 mb-4">
        <CustomSelect
          label="ACTION"
          formRegister={register}
          name="actionId"
          options={submittableActions}
          maxWidth="90%"
          width="90%"
          displayKey="display"
          defaultValue={submittableActions?.[0]?.id}
        />
      </div>
      <div className="w-100">
        <span className="mx-2" style={{ color: "red", fontSize: "0.75rem" }}>
          {approvalDetailsErrors > 0 && ` * Please limit to ${DESCRIPTION_CHAR_LIMIT} characters`}
        </span>
        <StyledHighlightTextArea>
          <Label className="label">DETAILS *</Label>

          <HighlightWithinTextarea
            value={approvalDetails || ""}
            onChange={handleInputChange}
            placeholder=""
            highlight={approvalDetailsHighlight(0, DESCRIPTION_CHAR_LIMIT)}
          />
        </StyledHighlightTextArea>
      </div>
    </form>
  );
}

export default React.memo(UserCreatedActionForm);
